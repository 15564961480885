export const LARGE_STRAIGHT = 'LARGE_STRAIGHT';
export const SMALL_STRAIGHT = 'SMALL_STRAIGHT';
export const CARGO_VAN = 'CARGO_VAN';
export const SPRINTER = 'SPRINTER';

export const STRING_TO_TYPE_MAP = {
    'LARGE STRAIGHT': LARGE_STRAIGHT,
    'SMALL STRAIGHT': SMALL_STRAIGHT,
    'CARGO VAN': CARGO_VAN,
    'VAN': SPRINTER,
    'SPRINTER VAN': SPRINTER,
    'SPRINTER': SPRINTER,
};

export const TYPE_TO_NAME_MAP = {
    [LARGE_STRAIGHT]: 'large straight',
    [SMALL_STRAIGHT]: 'small straight',
    [CARGO_VAN]: 'cargo van',
    [SPRINTER]: 'sprinter',
};
