import React from 'react';
import PropTypes from 'prop-types';
import block from 'bem-css-modules';
import CarInfo from '../../modals/carInfo';
import styles from '../../../scss/common/main-wrap.module.scss';

const b = block(styles);

export default function MainWrap(props) {
    const { children } = props;

    return (
        <>
            <div className={b()}>{children}</div>
            <CarInfo />
        </>
    );
}

MainWrap.propTypes = {
    children: PropTypes.any,
};
