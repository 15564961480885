import JSON_RPC from './API';

export default class Map {
    static getVehicles() {
        return JSON_RPC.request('getVehicles');
    }

    static createLoad(data) {
        return JSON_RPC.request('postLoad', data);
    }

    static getSettings() {
        return JSON_RPC.request('getSettings');
    }
}
