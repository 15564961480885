import { observable, action, computed } from 'mobx';
import geodist from 'geodist';
import MapService from '../services/Map';
import MapStore from './Map';
import { SEARCH_RADIUS_IN_MILES } from '../constants/googleMap';
import { getMarkerCoordinateWithOffset } from '../helpers/mapHelpers';
import { STRING_TO_TYPE_MAP } from '../constants/carTypes';

class Cars {
    @observable isFetching = false;
    @observable cachedCars = [];
    @observable activeCar = null;
    @observable shownInModalCar = null;
    @observable highlightedCarId = null;

    @computed get cars() {
        const { location } = MapStore;

        return location
            ? this.cachedCars
                  .map((car) => ({
                      ...car,
                      dist: geodist(
                          { lat: car.lat, lon: car.lng },
                          { lat: location.lat, lon: location.lng },
                      ),
                  }))
                  .filter(({ dist }) => dist <= SEARCH_RADIUS_IN_MILES)
                  .sort((a, b) => a.dist - b.dist)
            : this.cachedCars;
    }

    @action('set cached cars')
    setCachedCars(cachedCars) {
        this.cachedCars = cachedCars;
    }

    @action('set active car')
    setActiveCar = (activeCar) => {
        this.activeCar = activeCar;
    };

    @action('set highlighted car id')
    setHighlightedCarId = (highlightedCarId) => {
        this.highlightedCarId = highlightedCarId;
    };

    @action('reset active car')
    resetActiveCar = () => {
        this.setActiveCar(null);
    };

    @action('set shown in modal car')
    setShownInModalCar(shownInModalCar) {
        this.shownInModalCar = shownInModalCar;
    }

    @action('reset shown in modal car')
    resetShownInModalCar = () => {
        this.shownInModalCar = null;
    };

    @action('fetch')
    fetch = async () => {
        this.isFetching = true;

        try {
            const result = await MapService.getVehicles();
            const cars = result.map(
                ({
                    id,
                    availableGeo,
                    availableCity,
                    status,
                    statusName,
                    type,
                    team,
                    size,
                    payload,
                    make,
                    model,
                    year,
                }) => {
                    const [lat, lng] = availableGeo;

                    return {
                        id,
                        lat: getMarkerCoordinateWithOffset(lat),
                        lng: getMarkerCoordinateWithOffset(lng),
                        city: availableCity,
                        status,
                        statusName,
                        type: STRING_TO_TYPE_MAP[type],
                        team,
                        size,
                        payload,
                        make,
                        model,
                        year,
                    };
                },
            );

            this.setCachedCars(cars);
        } catch (e) {
        } finally {
            this.isFetching = false;
        }
    };
}

export default new Cars();
