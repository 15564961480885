import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import { Checkbox as MUCheckbox } from 'formik-material-ui';
import { FormControlLabel } from '@material-ui/core';

export default function Checkbox(props) {
    const { name, label } = props;

    return (
        <FormControlLabel
            control={<Field name={name} component={MUCheckbox} color="primary" />}
            label={label}
        />
    );
}

Checkbox.propTypes = {
    label: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
};
