import { observable, action } from 'mobx';
import { DEFAULT_BOUNDS, DEFAULT_CENTER, SEARCH_RADIUS_IN_MILES } from '../constants/googleMap';

class Map {
    @observable center = DEFAULT_CENTER;
    @observable location = null;
    @observable locationAddress = '';
    map = null;

    @action('set center')
    setCenter(center) {
        this.center = center;
    }

    @action('set location')
    setLocation(location, address) {
        this.location = location;
        this.locationAddress = address;
    }

    @action('handle center change')
    handleCenterChanged = () => {
        if (!this.map) {
            return;
        }

        try {
            const { lat, lng } = this.center;
            const latLng = this.map.getCenter();
            const _lat = latLng.lat();
            const _lng = latLng.lng();

            if (lat !== _lat || lng !== _lng) {
                this.setCenter({ lat: _lat, lng: _lng });
            }
        } catch (e) {}
    };

    @action('set map')
    setMap = (map) => {
        this.map = map;
        this.initiallyFitBounds();
    };

    fitLocationRadius = () => {
        if (!this.location) {
            return;
        }

        try {
            const circle = new window.google.maps.Circle({
                radius: SEARCH_RADIUS_IN_MILES * 1609.34, // Convert miles to meters
                center: this.location,
            });
            this.map.fitBounds(circle.getBounds(), 0);
            this.map.setZoom(this.map.getZoom() + 0.4); // Workaround to fix unexpected padding added around bounds by Google Maps https://issuetracker.google.com/issues/35820423
        } catch (e) {}
    };

    initiallyFitBounds = () => {
        try {
            const bounds = new window.google.maps.LatLngBounds(
                DEFAULT_BOUNDS.sw,
                DEFAULT_BOUNDS.ne,
            );
            this.map.fitBounds(bounds, 0);
        } catch (e) {}
    };
}

export default new Map();
