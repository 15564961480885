import React from 'react';
import { Autocomplete } from '@material-ui/lab';
import { TextField, Grid, IconButton } from '@material-ui/core';
import {
    LocationOn as LocationOnIcon,
    Search as SearchIcon,
    Clear as ClearIcon,
} from '@material-ui/icons';
import throttle from 'lodash/throttle';
import block from 'bem-css-modules';
import GeocoderService from '../../../../services/Geocoder';
import MapStore from '../../../../stores/Map';
import styles from '../../../../scss/pages/home/search.module.scss';

const FETCH_DELAY = 1000;
const b = block(styles);

class Search extends React.Component {
    state = {
        pattern: '',
        isFetching: false,
        options: [],
        selectedOption: null,
    };
    geocoderService = null;
    inputRef = null;

    handleInputChange = (event, value, reason) => {
        this.setState({
            pattern: value || '',
        });
    };

    handleChange = (e, option) => {
        this.setOption(option);
    };

    handleFormSubmit = (e) => {
        e.preventDefault();

        const { options } = this.state;
        const [firstOption] = options;

        this.setOption(firstOption);
    };

    setOption(option) {
        this.setState({
            selectedOption: option || null,
        });

        if (option) {
            const { position, address } = option;

            MapStore.setLocation(position, address);
            MapStore.setCenter(position);
            setTimeout(MapStore.fitLocationRadius, 0);
            this.inputRef.blur();
        } else {
            MapStore.setLocation(null);
            setTimeout(MapStore.initiallyFitBounds, 0);
        }
    }

    getOptionLabel = (option) => {
        return option.address || '';
    };

    fetch = throttle(async () => {
        const { pattern } = this.state;

        if (!pattern) {
            return this.setState({
                options: [],
            });
        }

        this.setState({
            isFetching: true,
        });

        try {
            const predictions = await this.geocoderService.geocode(pattern);

            this.setState({
                options: predictions.map(({ formatted_address, geometry: { location } }) => ({
                    address: formatted_address,
                    position: { lat: location.lat(), lng: location.lng() },
                })),
                isFetching: false,
            });
        } catch (e) {
            this.setState({
                isFetching: false,
            });
        }
    }, FETCH_DELAY);

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { pattern } = this.state;

        if (pattern !== prevState.pattern) {
            this.fetch();
        }
    }

    componentDidMount() {
        this.geocoderService = new GeocoderService();
    }

    render() {
        const { isFetching, options, selectedOption } = this.state;

        return (
            <div className={b()}>
                <Autocomplete
                    loadingText="Loading..."
                    noOptionsText="No results"
                    loading={isFetching}
                    value={selectedOption}
                    options={options}
                    onInputChange={this.handleInputChange}
                    onChange={this.handleChange}
                    getOptionLabel={this.getOptionLabel}
                    renderInput={({ InputProps, ...params }) => (
                        <form onSubmit={this.handleFormSubmit}>
                            <TextField
                                {...params}
                                label="Enter address or ZIP"
                                variant="outlined"
                                inputRef={(el) => (this.inputRef = el)}
                                fullWidth
                                InputProps={{
                                    ...InputProps,
                                    endAdornment: (
                                        <div className={InputProps.endAdornment.props.className}>
                                            {selectedOption && (
                                                <IconButton
                                                    aria-labelledby="Clear"
                                                    size="small"
                                                    onClick={this.handleChange}
                                                >
                                                    <ClearIcon fontSize="small" />
                                                </IconButton>
                                            )}
                                            <IconButton
                                                aria-labelledby="Search"
                                                size="small"
                                                type="submit"
                                            >
                                                <SearchIcon fontSize="small" />
                                            </IconButton>
                                        </div>
                                    ),
                                }}
                            />
                        </form>
                    )}
                    renderOption={({ address }) => (
                        <Grid container alignItems="center" spacing={2}>
                            <Grid item>
                                <LocationOnIcon />
                            </Grid>
                            <Grid item xs>
                                {address}
                            </Grid>
                        </Grid>
                    )}
                    filterOptions={(options) => options}
                />
            </div>
        );
    }
}

export default Search;
