import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import DateTimeField from '../../../../common/dateTimeField';

export default function DateTimePicker(props) {
    const { isDisabled, name } = props;

    return <Field name={name} component={DateTimeField} disabled={isDisabled} />;
}

DateTimePicker.propTypes = {
    isDisabled: PropTypes.bool,
    name: PropTypes.string.isRequired,
};
