import React from 'react';
import PropTypes from 'prop-types';
import { InfoWindow as GoogleMapsInfoWindow } from '@react-google-maps/api';
import { Grid } from '@material-ui/core';
import block from 'bem-css-modules';
import CarsStore from '../../../../stores/Cars';
import { getCarInfoString } from '../../../../helpers/carHelpers';
import { TYPE_TO_NAME_MAP } from '../../../../constants/carTypes';
import styles from '../../../../scss/pages/home/info-window.module.scss';

const b = block(styles);

export default function InfoWindow(props) {
    const {
        car: { id, lat, lng, size, payload, type, dist, ...car },
    } = props;

    const options = {};
    try {
        options.pixelOffset = new window.google.maps.Size(0, -38);
    } catch (e) {}

    return (
        <GoogleMapsInfoWindow
            position={{ lat, lng }}
            onCloseClick={CarsStore.resetActiveCar}
            options={options}
        >
            <section className={b()}>
                <Grid container spacing={2} justify="space-between">
                    <Grid item>
                        <h3 className={b('heading')}>
                            #{id} {TYPE_TO_NAME_MAP[type]}
                        </h3>
                    </Grid>
                    {typeof dist !== 'undefined' && (
                        <Grid item>
                            <p className={b('heading')}>{dist} mi</p>
                        </Grid>
                    )}
                </Grid>
                <div className={b('props')}>
                    <p className={b('prop', { capitalized: true })}>{getCarInfoString(car)}</p>
                    <p className={b('prop')}>Payload (lbs): {payload}</p>
                    <p className={b('prop')}>DIMS: {size.join(' x ')}</p>
                </div>
            </section>
        </GoogleMapsInfoWindow>
    );
}

InfoWindow.propTypes = {
    car: PropTypes.shape({
        id: PropTypes.number.isRequired,
        lat: PropTypes.number.isRequired,
        lng: PropTypes.number.isRequired,
        payload: PropTypes.number.isRequired,
        type: PropTypes.string.isRequired,
        size: PropTypes.array.isRequired,
    }).isRequired,
};
