import React from 'react';
import block from 'bem-css-modules';
import { observer } from 'mobx-react';
import { Grid, Typography, Box, IconButton } from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';
import { withPrefix } from '../../../../../helpers/pathHelpers';
import SettingsStore from '../../../../../stores/Settings';
import styles from '../../../../../scss/pages/home/welcome.module.scss';

const b = block(styles);

@observer
class Welcome extends React.Component {
    state = {
        isShown: true,
    };

    handleCloseBtnClick = () => {
        this.setState({
            isShown: false,
        });
    };

    render() {
        const { isShown } = this.state;
        const { company } = SettingsStore;

        if (!isShown) {
            return null;
        }

        return (
            <div className={b()}>
                <Grid container wrap="nowrap" spacing={2}>
                    <Grid item xs={8}>
                        <Box
                            component="h3"
                            fontWeight={700}
                            fontFamily="fontFamily"
                            className={b('heading')}
                        >
                            {`Welcome to ${company}!`}
                        </Box>
                        <Typography variant="body2" color="textSecondary">
                            Here you can see our vehicles that are&nbsp;available around the country
                            <br /> at the moment. Please enter a ZIP <br />
                            code to find the best fit for your
                            <br /> load. Book it with just a few <br />
                            clicks!
                        </Typography>
                    </Grid>
                    <Grid item component="aside" xs={4} className={b('aside')}>
                        <img
                            src={withPrefix('/sidebar/welcome-globe.jpg')}
                            srcSet={withPrefix('/sidebar/welcome-globe@2x.jpg 2x')}
                            alt={`Welcome to ${company}!`}
                            className={b('img')}
                        />
                    </Grid>
                </Grid>
                <Box className={b('close')}>
                    <IconButton size="small" onClick={this.handleCloseBtnClick}>
                        <CloseIcon fontSize="small" />
                    </IconButton>
                </Box>
            </div>
        );
    }
}

export default Welcome;
