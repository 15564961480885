import React from 'react';
import block from 'bem-css-modules';
import Search from './Search';
import CarsList from './carsList';
import styles from '../../../../scss/pages/home/sidebar.module.scss';

const b = block(styles);

export default function Sidebar(props) {
    return (
        <div className={b()}>
            <Search />
            <CarsList />
        </div>
    );
}
