import React from 'react';
import { observer } from 'mobx-react';
import ModalsStore from '../../../stores/Modals';

export default function withModalShown(modalName) {
    return function(WrappedComponent) {
        function WithModalShown(props) {
            return (
                <WrappedComponent
                    isOpen={ModalsStore.shownList[modalName] || false}
                    hideModal={ModalsStore.hideModal}
                    titleId={`modal-${modalName}`}
                    {...props}
                />
            );
        }

        return observer(WithModalShown);
    };
}
