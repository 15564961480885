import * as yup from 'yup';

export const validationSchema = yup.object().shape({
    pickUpAt: yup.string().required('Enter pick up address'),
    pickUpDate: yup
        .date()
        .nullable()
        .when('ASAP', (ASAP, schema) => {
            return ASAP
                ? schema.transform(() => null)
                : schema.required('Provide pickup date or check ASAP option');
        }),
    ASAP: yup.bool(),
    deliverTo: yup.string().required('Enter delivery address'),
    deliveryDate: yup
        .date()
        .nullable()
        .when('direct', (direct, schema) => {
            return direct
                ? schema.transform(() => null)
                : schema.required('Provide pickup date or check Direct option');
        }),
    direct: yup.bool(),
    pieces: yup
        .number()
        .nullable()
        .transform((value) => parseFloat(value) || null),
    weight: yup
        .number()
        .nullable()
        .transform((value) => parseFloat(value) || null),
    hazardous: yup.bool(),
    dockLevel: yup.bool(),
    note: yup.string(),
    postedBy: yup.string().required('What is your name?'),
    company: yup.string().required('Enter company name'),
    phone: yup.string().required('How to contact you?'),
    email: yup.string().required('Enter email'),
});
