import { observable, action } from 'mobx';
import MapService from '../services/Map';

class Settings {
    @observable isFetching = false;
    @observable company = 'Logistics System';
    @observable address = '';
    @observable phone = '';

    @action('set company')
    setCompany(company) {
        this.company = company;
    }

    @action('set address')
    setAddress(address) {
        this.address = address;
    }

    @action('set phone')
    setPhone(phone) {
        this.phone = phone;
    }

    @action('fetch')
    fetch = async () => {
        this.isFetching = true;

        try {
            const { company, address, phone } = await MapService.getSettings();

            this.setCompany(company);
            this.setAddress(address);
            this.setPhone(phone);
        } catch (e) {
        } finally {
            this.isFetching = false;
        }
    };
}

export default new Settings();
