import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { DateTimePicker, Checkbox, TextField } from './fields';

export default function Request(props) {
    const { values } = props;

    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Grid direction="column" spacing={1} container>
                <Grid item>
                    <TextField name="pickUpAt" label="Pickup at *" />
                </Grid>
                <Grid item>
                    <Grid spacing={2} container>
                        <Grid xs={6} item>
                            <DateTimePicker name="pickUpDate" isDisabled={values.ASAP} />
                        </Grid>
                        <Grid item>
                            <Checkbox name="ASAP" label="ASAP" />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item>
                    <TextField name="deliverTo" label="Deliver to *" />
                </Grid>
                <Grid item>
                    <Grid spacing={2} container>
                        <Grid xs={6} item>
                            <DateTimePicker name="deliveryDate" isDisabled={values.direct} />
                        </Grid>
                        <Grid item>
                            <Checkbox name="direct" label="Direct" />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item>
                    <Grid spacing={2} container>
                        <Grid xs={6} item>
                            <TextField name="pieces" label="Pieces" />
                        </Grid>
                        <Grid xs={6} item>
                            <TextField name="weight" label="Weight" />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item>
                    <Grid spacing={4} container>
                        <Grid item>
                            <Checkbox name="hazardous" label="Hazardous" />
                        </Grid>
                        <Grid item>
                            <Checkbox name="dockLevel" label="Dock level" />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item>
                    <TextField name="note" label="Note" rows={2} rowsMax={5} multiline />
                </Grid>
                <Grid item>
                    <Grid spacing={2} container>
                        <Grid xs={6} item>
                            <TextField name="postedBy" label="Posted by (name) *" />
                        </Grid>
                        <Grid xs={6} item>
                            <TextField name="phone" label="Phone *" type="tel" />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item>
                    <Grid spacing={2} container>
                        <Grid xs={6} item>
                            <TextField name="company" label="Company *" />
                        </Grid>
                        <Grid xs={6} item>
                            <TextField name="email" label="Email *" type="email" />
                        </Grid>
                    </Grid>

                </Grid>
            </Grid>
        </MuiPickersUtilsProvider>
    );
}

Request.propTypes = {
    values: PropTypes.object.isRequired,
};
