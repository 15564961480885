import React from 'react';
import block from 'bem-css-modules';
import { CircularProgress, Container, List, Typography, Box } from '@material-ui/core';
import { observer } from 'mobx-react';
import Item from './Item';
import Welcome from './Welcome';
import CarsStore from '../../../../../stores/Cars';
import MapStore from '../../../../../stores/Map';
import { SEARCH_RADIUS_IN_MILES } from '../../../../../constants/googleMap';
import styles from '../../../../../scss/pages/home/cars-list.module.scss';

const b = block(styles);

function CarsList(props) {
    const { isFetching, cars, highlightedCarId } = CarsStore;
    const { location } = MapStore;
    let content = null;

    if (location) {
        content = (
            <>
                {isFetching && <CircularProgress size={20} />}
                {!isFetching && cars.length > 0 && (
                    <List disablePadding>
                        {cars.map((car) => (
                            <Item
                                key={car.id}
                                car={car}
                                isHighlighted={car.id === highlightedCarId}
                            />
                        ))}
                    </List>
                )}
                {!isFetching && cars.length === 0 && (
                    <Box ml={2} mr={2} pt={1} pb={1}>
                        <Typography>
                            There are no cars available within {SEARCH_RADIUS_IN_MILES} miles
                        </Typography>
                    </Box>
                )}
            </>
        );
    } else {
        content = <Welcome />;
    }

    return (
        <Container className={b(null, { loading: isFetching })} disableGutters>
            {content}
        </Container>
    );
}

export default observer(CarsList);
