import React from 'react';
import PropTypes from 'prop-types';
import { Marker as GoogleMapMarker } from '@react-google-maps/api';
import ModalsStore from '../../../../stores/Modals';
import { withPrefix } from '../../../../helpers/pathHelpers';

const DEFAULT_ICON_URL = withPrefix('/map/pin.png');
const SCALED_ICON_URL = withPrefix('/map/pin-scaled.png');

export default class CarMarker extends React.Component {
    handleMouseOver = () => {
        const { car, setActiveCar } = this.props;

        if (setActiveCar) {
            setActiveCar(car);
        }
    };

    handleMouseOut = () => {
        const { setActiveCar } = this.props;

        if (setActiveCar) {
            setActiveCar(null);
        }
    };

    handleClick = () => {
        const { car } = this.props;
        ModalsStore.showCarInfoModal(car);
    };

    render() {
        const {
            car: { lat, lng },
            clusterer,
            isHighlighted,
        } = this.props;

        /* eslint-disable jsx-a11y/mouse-events-have-key-events */
        return (
            <GoogleMapMarker
                position={{ lat, lng }}
                clusterer={clusterer}
                onMouseOver={this.handleMouseOver}
                onMouseOut={this.handleMouseOut}
                onClick={this.handleClick}
                options={{
                    icon: isHighlighted ? SCALED_ICON_URL : DEFAULT_ICON_URL,
                }}
            />
        );
        /* eslint-disable jsx-a11y/mouse-events-have-key-events */
    }
}

CarMarker.propTypes = {
    car: PropTypes.shape({
        lat: PropTypes.number.isRequired,
        lng: PropTypes.number.isRequired,
        type: PropTypes.string.isRequired,
    }).isRequired,
    clusterer: PropTypes.any,
    setActiveCar: PropTypes.func,
    isHighlighted: PropTypes.bool,
};
