import PropTypes from 'prop-types';
import React from 'react';
import { Snackbar, SnackbarContent, IconButton } from '@material-ui/core';
import {
    CheckCircle as CheckCircleIcon,
    Error as ErrorIcon,
    Close as CloseIcon,
} from '@material-ui/icons';
import red from '@material-ui/core/colors/red';
import green from '@material-ui/core/colors/green';
import block from 'bem-css-modules';
import { ERROR, SUCCESS } from '../../../constants/messageVariants';
import styles from '../../../scss/common/message.module.scss';

const HIDE_TIMEOUT = 5000;
const variantToIconMap = {
    [SUCCESS]: CheckCircleIcon,
    [ERROR]: ErrorIcon,
};
const variantToColorMap = {
    [SUCCESS]: green[900],
    [ERROR]: red[900],
};
const ANCHOR_ORIGIN = {
    vertical: 'bottom',
    horizontal: 'left',
};
const b = block(styles);

export default function Message(props) {
    const { isOpen, variant, children, onClose, handleCloseClick } = props;
    const Icon = variantToIconMap[variant] || null;
    const color = variantToColorMap[variant];
    const messageId = 'request-message';

    return (
        <Snackbar
            open={isOpen}
            autoHideDuration={HIDE_TIMEOUT}
            anchorOrigin={ANCHOR_ORIGIN}
            onClose={onClose}
        >
            <SnackbarContent
                aria-describedby={messageId}
                message={
                    <span id={messageId} className={b('content')}>
                        <Icon className={b('icon')} />
                        {children}
                    </span>
                }
                action={[
                    <IconButton
                        key="close"
                        aria-label="close"
                        color="inherit"
                        onClick={handleCloseClick}
                    >
                        <CloseIcon />
                    </IconButton>,
                ]}
                style={{
                    backgroundColor: color,
                }}
            />
        </Snackbar>
    );
}

Message.propTypes = {
    children: PropTypes.any.isRequired,
    handleCloseClick: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    variant: PropTypes.oneOf([SUCCESS, ERROR]).isRequired,
};
