export default class Geocoder {
    constructor() {
        try {
            this.service = new window.google.maps.Geocoder();
        } catch (e) {}
    }

    geocode(pattern) {
        return new Promise((resolve, reject) => {
            if (!this.service) {
                return reject();
            }

            try {
                this.service.geocode(
                    {
                        address: pattern,
                    },
                    resolve,
                );
            } catch (e) {
                reject();
            }
        });
    }
}
