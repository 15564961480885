import React from 'react';
import PropTypes from 'prop-types';
import { Marker as GoogleMapMarker } from '@react-google-maps/api';
import { withPrefix } from '../../../../helpers/pathHelpers';

const DEFAULT_ICON_URL = withPrefix('/map/pin-search.png');

export default class SearchMarker extends React.Component {
    render() {
        const { lat, lng, clusterer } = this.props;

        return (
            <GoogleMapMarker
                position={{ lat, lng }}
                clusterer={clusterer}
                options={{
                    icon: DEFAULT_ICON_URL,
                }}
            />
        );
    }
}

SearchMarker.propTypes = {
    lat: PropTypes.number.isRequired,
    lng: PropTypes.number.isRequired,
    clusterer: PropTypes.any,
};
