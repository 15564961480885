import React from 'react';
import PropTypes from 'prop-types';
import { TextField as MUTextField } from 'formik-material-ui';
import { Field } from 'formik';

export default function TextField(props) {
    const { name, label, ...rest } = props;

    return (
        <Field
            {...rest}
            component={MUTextField}
            name={name}
            label={label}
            variant="outlined"
            size="small"
            fullWidth
        />
    );
}

TextField.propTypes = {
    label: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
};
