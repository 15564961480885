import React from 'react';
import { observer } from 'mobx-react';
import { CircularProgress, List, Grid, Chip, Typography, Divider } from '@material-ui/core';
import {
    Fingerprint as FingerprintIcon,
    LocalShipping as LocalShippingIcon,
    LocationCity as LocationCityIcon,
    Height as HeightIcon,
    Info as InfoIcon,
    Category as CategoryIcon,
    GroupWork as GroupWorkIcon,
    Phone as PhoneIcon,
    Business as BusinessIcon,
} from '@material-ui/icons';
import Item from './Item';
import CarsStore from '../../../stores/Cars';
import SettingsStore from '../../../stores/Settings';
import { TYPE_TO_NAME_MAP } from '../../../constants/carTypes';

function Info(props) {
    const { shownInModalCar } = CarsStore;

    if (!shownInModalCar) {
        return <CircularProgress size={50} />;
    }

    const { id, city, statusName, size, payload, team, type } = shownInModalCar;
    const { company, address, phone } = SettingsStore;

    return (
        <>
            {team && <Chip icon={<GroupWorkIcon />} label="Team" color="primary" />}
            <Grid container spacing={4}>
                <Grid item>
                    <List disablePadding>
                        <Item Icon={FingerprintIcon} value="ID" title={id} />
                        <Item
                            Icon={CategoryIcon}
                            value="Type"
                            title={TYPE_TO_NAME_MAP[type].toUpperCase()}
                        />
                        <Item Icon={LocationCityIcon} value="Location" title={city} />
                    </List>
                </Grid>
                <Grid item>
                    <List disablePadding>
                        <Item Icon={InfoIcon} value="Status" title={statusName} />
                        <Item Icon={HeightIcon} value="DIMS, in" title={size.join(' x ')} />
                        <Item Icon={LocalShippingIcon} value="Payload (lbs)" title={payload} />
                    </List>
                </Grid>
            </Grid>
            <Divider light />
            <br />
            <Typography variant="h6" component="h3">
                Carrier Information
            </Typography>
            <List disablePadding>
                <Item Icon={BusinessIcon} value="Transportation company" title={company} />
                <Item Icon={PhoneIcon} value="Phone" title={phone} />
                <Item Icon={LocationCityIcon} value="Address" title={address} />
            </List>
        </>
    );
}

export default observer(Info);
