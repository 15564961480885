import React from 'react';
import PropTypes from 'prop-types';
import { Button, DialogActions, DialogContent, Grid, LinearProgress } from '@material-ui/core';
import { Form } from 'formik';
import Info from './Info';
import Request from './request';

export default function Content(props) {
    const { isSubmitting, isValid, hideModal, values } = props;

    return (
        <Form>
            <LinearProgress
                variant="query"
                hidden={!isSubmitting}
                style={{ position: 'absolute', width: '100%' }}
            />
            <DialogContent dividers>
                <Grid container spacing={4}>
                    <Grid item xs={6}>
                        <Info />
                    </Grid>
                    <Grid item xs={6}>
                        <Request values={values} />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    disabled={isSubmitting || !isValid}
                    disableElevation
                >
                    Send request
                </Button>
                <Button onClick={hideModal} color="primary">
                    Close
                </Button>
            </DialogActions>
        </Form>
    );
}

Content.propTypes = {
    isSubmitting: PropTypes.bool.isRequired,
    isValid: PropTypes.bool.isRequired,
    hideModal: PropTypes.func.isRequired,
    values: PropTypes.object.isRequired,
};
