import React from 'react';
import PropTypes from 'prop-types';
import { ListItem, ListItemText, Grid } from '@material-ui/core';
import block from 'bem-css-modules';
import CarsStore from '../../../../../stores/Cars';
import ModalsStore from '../../../../../stores/Modals';
import { getCarInfoString } from '../../../../../helpers/carHelpers';
import { withPrefix } from '../../../../../helpers/pathHelpers';
import {
    LARGE_STRAIGHT,
    SMALL_STRAIGHT,
    CARGO_VAN,
    SPRINTER,
    TYPE_TO_NAME_MAP,
} from '../../../../../constants/carTypes';
import styles from '../../../../../scss/pages/home/cars-list-item.module.scss';

const b = block(styles);
const CAR_TYPE_TO_IMAGE_URL = {
    [LARGE_STRAIGHT]: withPrefix('/car-types/large-straight.png'),
    [SMALL_STRAIGHT]: withPrefix('/car-types/small-straight.png'),
    [CARGO_VAN]: withPrefix('/car-types/cargo-van.png'),
    [SPRINTER]: withPrefix('/car-types/sprinter.png'),
};

export default class Item extends React.Component {
    handleFocusAndMouseEnter = () => {
        const {
            car: { id },
        } = this.props;
        CarsStore.setHighlightedCarId(id);
    };

    handleMouseLeave = () => {
        CarsStore.setHighlightedCarId(null);
    };

    handleClick = () => {
        const { car } = this.props;
        ModalsStore.showCarInfoModal(car);
    };

    render() {
        const {
            car: { id, type, payload, size, dist, ...car },
            isHighlighted,
        } = this.props;

        return (
            <ListItem
                onMouseEnter={this.handleFocusAndMouseEnter}
                onMouseLeave={this.handleMouseLeave}
                onFocus={this.handleFocusAndMouseEnter}
                onClick={this.handleClick}
                component="li"
                selected={isHighlighted}
                button
                divider
            >
                <ListItemText>
                    <Grid container alignItems="center" spacing={2} wrap="nowrap">
                        <Grid item>
                            <img
                                src={CAR_TYPE_TO_IMAGE_URL[type]}
                                className={b('img')}
                                alt={`#${id}`}
                            />
                        </Grid>
                        <Grid item className={b('main')}>
                            <Grid container spacing={2} justify="space-between">
                                <Grid item>
                                    <h3 className={b('heading')}>
                                        #{id} {TYPE_TO_NAME_MAP[type]}
                                    </h3>
                                </Grid>
                                <Grid item className={b('distance')}>
                                    <p className={b('heading')}>{dist} mi</p>
                                </Grid>
                            </Grid>
                            <div className={b('content')}>
                                <p className={b('info', { capitalized: true })}>
                                    {getCarInfoString(car)}
                                </p>
                                <p className={b('info')}>Payload (lbs): {payload}</p>
                                <p className={b('info')}>DIMS: {size.join(' x ')}</p>
                            </div>
                        </Grid>
                    </Grid>
                </ListItemText>
            </ListItem>
        );
    }
}

Item.propTypes = {
    car: PropTypes.shape({
        id: PropTypes.number.isRequired,
        city: PropTypes.string.isRequired,
    }),
    isHighlighted: PropTypes.bool,
};
