import React from 'react';
import { LoadScript } from '@react-google-maps/api';
import { CircularProgress } from '@material-ui/core';
import block from 'bem-css-modules';
import { observer } from 'mobx-react';
import Map from './map';
import Sidebar from './sidebar';
import CarsStore from '../../../stores/Cars';
import SettingsStore from '../../../stores/Settings';
import { API_KEY, LANGUAGE, SCRIPT_LIBRARIES } from '../../../constants/googleMap';
import styles from '../../../scss/pages/home/home.module.scss';

const FETCH_TIMEOUT = 3 * 60 * 1000;
const b = block(styles);

@observer
class HomePageContent extends React.Component {
    fetchTimeInterval = null;

    fetchCars = () => {
        CarsStore.fetch().then(() => {
            this.fetchTimeInterval = setTimeout(this.fetchCars, FETCH_TIMEOUT);
        });
    };

    fetchSettings() {
        SettingsStore.fetch();
    }

    componentDidMount() {
        this.fetchCars();
        this.fetchSettings();
    }

    componentWillUnmount() {
        if (this.fetchTimeInterval) {
            clearTimeout(this.fetchTimeInterval);
        }
    }

    render() {
        const loadingElement = (
            <CircularProgress size={50} className={b('loader', { center: true })} />
        );
        const { isFetching: carFetching } = CarsStore;
        const { isFetching: settingsFetching } = SettingsStore;

        const isFetching = carFetching || settingsFetching;
        console.debug('fetching', carFetching, settingsFetching);

        return (
            <div className={b()}>
                <LoadScript
                    googleMapsApiKey={API_KEY}
                    language={LANGUAGE}
                    libraries={SCRIPT_LIBRARIES}
                    loadingElement={loadingElement}
                >
                    <Map />
                    <Sidebar />
                    {isFetching && (
                        <CircularProgress size={20} className={b('loader', { corner: true })} />
                    )}
                </LoadScript>
            </div>
        );
    }
}

export default HomePageContent;
