import React from 'react';
import PropTypes from 'prop-types';
import { Avatar, ListItem, ListItemAvatar, ListItemText } from '@material-ui/core';

export default function Item(props) {
    const { Icon, title, value } = props;

    return (
        <ListItem disableGutters>
            <ListItemAvatar>
                <Avatar>
                    <Icon />
                </Avatar>
            </ListItemAvatar>
            <ListItemText primary={title} secondary={value} />
        </ListItem>
    );
}

Item.propTypes = {
    Icon: PropTypes.any.isRequired,
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};
