import React from 'react';
import { Helmet } from 'react-helmet';
import MainWrap from '../components/common/mainWrap';
import HomePageContent from '../components/pages/home';

export default function HomePage(props) {
    return (
        <MainWrap>
            <Helmet>
                <title>ETL</title>
            </Helmet>
            <HomePageContent />
        </MainWrap>
    );
}
