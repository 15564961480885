import React from 'react';
import { KeyboardDateTimePicker } from '@material-ui/pickers';

export default class DateTimeField extends React.Component {
    handleError = (error) => {
        const {
            field: { name },
            form,
        } = this.props;
        const currentError = form.errors[name];

        if (error !== currentError) {
            form.setFieldError(name, error);
        }
    };

    handleChange = (value) => {
        const {
            field: { name },
            form,
        } = this.props;
        form.setFieldValue(name, value);
    };

    render() {
        const {
            field: { name, value },
            form,
            ...rest
        } = this.props;
        const currentError = form.errors[name];

        return (
            <KeyboardDateTimePicker
                name={name}
                value={value}
                format="MM/dd/yyyy, HH:mm"
                helperText={currentError}
                error={Boolean(currentError)}
                onError={this.handleError}
                onChange={this.handleChange}
                clearable
                disablePast
                fullWidth
                {...rest}
            />
        );
    }
}
