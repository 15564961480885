import React from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogTitle } from '@material-ui/core';
import { Formik } from 'formik';
import withModalShown from '../../HOCs/withModalShown';
import Content from './Content';
import Message from '../../common/message';
import MapService from '../../../services/Map';
import MapStore from '../../../stores/Map';
import CarsStore from '../../../stores/Cars';
import { validationSchema } from '../../../validationSchemas/requestForm';
import { CAR_INFO } from '../../../constants/modalNames';

class CarInfo extends React.Component {
    state = {
        isErrorMsgShown: false,
        isSuccessMsgShown: false,
    };

    handleSubmit = async (values, { resetForm, setSubmitting }) => {
        const { shownInModalCar } = CarsStore;

        if (!shownInModalCar) {
            return setSubmitting(false);
        }

        try {
            setSubmitting(true);
            values = {
                ...validationSchema.cast(values),
                vehicleId: shownInModalCar.id,
            };

            await MapService.createLoad(values);
            this.setState({
                isSuccessMsgShown: true,
            });
            resetForm();
        } catch (e) {
            this.setState({
                isErrorMsgShown: true,
            });
        }
    };

    resetMessagesState = () => {
        this.setState({
            isErrorMsgShown: false,
            isSuccessMsgShown: false,
        });
    };

    render() {
        const { isOpen, hideModal, titleId } = this.props;
        const { isErrorMsgShown, isSuccessMsgShown } = this.state;
        const { locationAddress } = MapStore;
        const initialValues = {
            pickUpAt: locationAddress || '',
            pickUpDate: new Date(),
            ASAP: false,
            deliverTo: '',
            deliveryDate: new Date(),
            direct: false,
            pieces: '',
            weight: '',
            hazardous: false,
            dockLevel: false,
            note: '',
            postedBy: '',
            company: '',
            phone: '',
            email: '',
        };

        return (
            <Dialog
                open={isOpen}
                aria-labelledby={titleId}
                maxWidth="md"
                onExited={CarsStore.resetShownInModalCar}
                fullWidth
            >
                <DialogTitle id={titleId}>Vehicle Information</DialogTitle>
                <Formik
                    initialValues={initialValues}
                    onSubmit={this.handleSubmit}
                    validationSchema={validationSchema}
                >
                    {({ values, isSubmitting, isValid }) => (
                        <Content
                            hideModal={hideModal}
                            isSubmitting={isSubmitting}
                            isValid={isValid}
                            values={values}
                        />
                    )}
                </Formik>
                <Message
                    variant="error"
                    isOpen={isErrorMsgShown}
                    handleCloseClick={this.resetMessagesState}
                    onClose={this.resetMessagesState}
                >
                    Ooops… Something went wrong. Please try again later.
                </Message>
                <Message
                    variant="success"
                    isOpen={isSuccessMsgShown}
                    handleCloseClick={this.resetMessagesState}
                    onClose={this.resetMessagesState}
                >
                    Thanks for the request! We will contact you shortly.
                </Message>
            </Dialog>
        );
    }
}

CarInfo.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    hideModal: PropTypes.func,
    titleId: PropTypes.string,
};

export default withModalShown(CAR_INFO)(CarInfo);
