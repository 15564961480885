import React from 'react';
import { GoogleMap } from '@react-google-maps/api';
import block from 'bem-css-modules';
import { observer } from 'mobx-react';
import Markers from './Markers';
import InfoWindow from './InfoWindow';
import MapStore from '../../../../stores/Map';
import CarsStore from '../../../../stores/Cars';
import { DEFAULT_ZOOM, OPTIONS } from '../../../../constants/googleMap';
import styles from '../../../../scss/pages/home/map.module.scss';

const b = block(styles);

function Map(props) {
    const { center, handleCenterChanged, setMap } = MapStore;
    const { activeCar } = CarsStore;

    return (
        <div className={b()}>
            <GoogleMap
                zoom={DEFAULT_ZOOM}
                center={center}
                mapContainerClassName={b('map')}
                options={OPTIONS}
                onLoad={setMap}
                onCenterChanged={handleCenterChanged}
            >
                <Markers />
                {activeCar && <InfoWindow car={activeCar} />}
            </GoogleMap>
        </div>
    );
}

export default observer(Map);
