export const API_KEY = process.env.GATSBY_GOOGLE_MAP_API_KEY;
export const LANGUAGE = 'en';
export const DEFAULT_CENTER = { lat: 0, lng: 0 };
export const DEFAULT_ZOOM = 2;
export const SCRIPT_LIBRARIES = ['places'];
export const OPTIONS = {
    fullscreenControl: false,
    mapTypeControl: false,
    streetViewControl: false,
    zoomControlOptions: {
        position: 8,
    },
};
export const DEFAULT_BOUNDS = {
    sw: { lat: 25.820898, lng: -124.712891 },
    ne: { lat: 49.38024, lng: -66.940662 },
};
export const SEARCH_RADIUS_IN_MILES = 100;
