import React from 'react';
import { MarkerClusterer } from '@react-google-maps/api';
import { observer } from 'mobx-react';
import CarMarker from './CarMarker';
import SearchMarker from './SearchMarker';
import CarsStore from '../../../../stores/Cars';
import MapStore from '../../../../stores/Map';

@observer
class Markers extends React.Component {
    setActiveCar = (car) => {
        const { setActiveCar, resetActiveCar, setHighlightedCarId } = CarsStore;

        if (car) {
            setActiveCar(car);
            setHighlightedCarId(car.id);
        } else {
            resetActiveCar();
            setHighlightedCarId(null);
        }
    };

    render() {
        const { cars, highlightedCarId } = CarsStore;
        const { location } = MapStore;

        return (
            <MarkerClusterer>
                {(clusterer) =>
                    cars
                        .map((car) => (
                            <CarMarker
                                key={car.id}
                                car={car}
                                clusterer={clusterer}
                                setActiveCar={this.setActiveCar}
                                isHighlighted={highlightedCarId && highlightedCarId === car.id}
                            />
                        ))
                        .concat([
                            location ? (
                                <SearchMarker lat={location.lat} lng={location.lng} />
                            ) : null,
                        ])
                }
            </MarkerClusterer>
        );
    }
}

export default Markers;
