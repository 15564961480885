import { observable, action } from 'mobx';
import CarsStore from './Cars';
import * as modalNames from '../constants/modalNames';

class Modals {
    @observable shownList = {};
    shownStack = [];

    @action('show car info modal')
    showCarInfoModal = (car) => {
        CarsStore.setShownInModalCar(car);
        this.showModal(modalNames.CAR_INFO);
    };

    showModal = (modalName, callback) => {
        this.shownList[modalName] = true;
        this.shownStack.push({ modalName, callback });
    };

    hideModal = () => {
        if (this.shownStack.length) {
            const { modalName, callback } = this.shownStack.pop();
            delete this.shownList[modalName];

            if (typeof callback === 'function') {
                callback();
            }
        }
    };
}

export default new Modals();
